import gql from 'graphql-tag';

export const LIST_ELIGIBLE_PAYER_PLAN_NAMES = gql`
  query ListEligiblePayerPlanNames {
    listEligiblePayerPlanNames
  }
`;

export const LIST_LEAD_ELIGIBILITY_CHECKS = gql`
  query ListLeadEligibilityChecks($limit: Int, $offset: Int) {
    listLeadEligibilityCheckResponse(limit: $limit, offset: $offset) {
      firstName
      lastName
      gender
      dateOfBirth
      centerState
      memberID
      nationalPayorID
      payorName
      planStatus
      networkStatus
      checkedAt
      description
      planNames
    }
  }
`;

export const LIST_REALTIME_ELIGIBILITY_CHECKS = gql`
  query ListRealtimeEligibilityChecks($limit: Int, $offset: Int) {
    listRealtimeEligibilityChecks(limit: $limit, offset: $offset) {
      firstName
      lastName
      gender
      dateOfBirth
      centerState
      memberID
      nationalPayorID
      payorName
      planStatus
      networkStatus
      checkedAt
      description
      planNames
      personId
    }
  }
`;

export const ADD_ELIGIBLE_PAYER_PLAN_NAMES = gql`
  mutation AddEligiblePayerPlanNames($input: PapiEligiblePayerPlanNamesInput!) {
    addEligiblePayerPlanNames(input: $input)
  }
`;
