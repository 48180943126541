import { Tabs, Table, Button, message, Input, Icon } from 'antd';
import React, { FC, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ViewerContext } from 'app/contexts/ViewerContext';
import { PapiRole } from 'app/types/generated/globalTypes';
import { TableProps } from 'antd/lib/table';

import {
  LIST_REALTIME_ELIGIBILITY_CHECKS,
  ADD_ELIGIBLE_PAYER_PLAN_NAMES,
  LIST_LEAD_ELIGIBILITY_CHECKS,
  LIST_ELIGIBLE_PAYER_PLAN_NAMES,
} from './query';

const { TabPane } = Tabs;

interface LeadEligibilityResponse {
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  centerState: string;
  memberID: string;
  nationalPayorID: string;
  payorName: string | null;
  planStatus: string;
  networkStatus: string;
  checkedAt: string | null;
  description: string | null;
  planNames: string | null;
  personId: string | null;
}

const StyledTable = styled(
  Table as React.ComponentType<TableProps<LeadEligibilityResponse>>
)`
  .ant-table-body {
    overflow-x: auto;
  }
`;

const LeadEligibilityPage: FC = () => {
  const [activeTab, setActiveTab] = useState('pre-member-oon');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const viewer = React.useContext(ViewerContext);
  const isAdmin =
    viewer?.role === PapiRole.PARSLEY_ADMIN ||
    viewer?.role === PapiRole.PARSLEY_SUPERADMIN;

  const {
    data: leadData,
    loading: leadLoading,
    refetch: refetchLeadData,
  } = useQuery(LIST_LEAD_ELIGIBILITY_CHECKS);
  const {
    data: realtimeData,
    loading: realtimeLoading,
    refetch: refetchRealtimeData,
  } = useQuery(LIST_REALTIME_ELIGIBILITY_CHECKS);
  const { data: eligiblePlanData, refetch: refetchEligiblePlans } = useQuery(
    LIST_ELIGIBLE_PAYER_PLAN_NAMES
  );
  const [addEligiblePayerPlanNames] = useMutation(
    ADD_ELIGIBLE_PAYER_PLAN_NAMES
  );

  const handleAddToInnList = async (record: LeadEligibilityResponse) => {
    try {
      message.loading({
        content: 'Adding to network coverage list...',
        key: 'addToInn',
      });
      const result = await addEligiblePayerPlanNames({
        variables: {
          input: {
            planNames: [record.planNames],
          },
        },
      });

      if (result.data?.addEligiblePayerPlanNames) {
        message.success({
          content: 'Successfully added to network coverage list',
          key: 'addToInn',
        });
        // Refetch all queries to update the data
        await Promise.all([
          refetchLeadData(),
          refetchRealtimeData(),
          refetchEligiblePlans(),
        ]);
      } else {
        message.warning({
          content: 'Failed to add to network coverage list',
          key: 'addToInn',
        });
      }
    } catch (error) {
      message.error({
        content: 'Failed to add to network coverage list',
        key: 'addToInn',
      });
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const commonColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (text: string, record: LeadEligibilityResponse) =>
        `${record.firstName} ${record.lastName}`,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value: string, record: LeadEligibilityResponse) =>
        `${record.firstName} ${record.lastName}`
          .toLowerCase()
          .includes(value.toLowerCase()),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? '#1890ff' : undefined }}
        />
      ),
    },
    {
      title: 'Center',
      dataIndex: 'centerState',
      key: 'centerState',
      width: 150,
      filters: [
        { text: 'CA', value: 'CA' },
        { text: 'NY', value: 'NY' },
      ],
      onFilter: (value: string, record: LeadEligibilityResponse) =>
        record.centerState === value,
    },
    {
      title: 'Insurance Member ID',
      dataIndex: 'memberID',
      key: 'memberID',
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search ID"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value: string, record: LeadEligibilityResponse) =>
        record.memberID.toLowerCase().includes(value.toLowerCase()),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? '#1890ff' : undefined }}
        />
      ),
    },
    {
      title: 'Payor ID',
      dataIndex: 'nationalPayorID',
      key: 'nationalPayorID',
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search payor ID"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value: string, record: LeadEligibilityResponse) =>
        record.nationalPayorID.toLowerCase().includes(value.toLowerCase()),
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? '#1890ff' : undefined }}
        />
      ),
    },
    {
      title: 'Payor Name',
      dataIndex: 'payorName',
      key: 'payorName',
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search payor name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value: string, record: LeadEligibilityResponse) =>
        record.payorName?.toLowerCase().includes(value.toLowerCase()) ?? false,
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? '#1890ff' : undefined }}
        />
      ),
    },
    {
      title: 'Plan Name',
      dataIndex: 'planNames',
      key: 'planNames',
      width: 150,
    },
    {
      title: 'Plan Status',
      dataIndex: 'planStatus',
      key: 'planStatus',
      width: 120,
    },
    {
      title: 'Network Status',
      dataIndex: 'networkStatus',
      key: 'networkStatus',
      width: 180,
    },
    {
      title: 'Checked At',
      dataIndex: 'checkedAt',
      key: 'checkedAt',
      width: 150,
      sorter: (a: LeadEligibilityResponse, b: LeadEligibilityResponse) => {
        const dateA = a.checkedAt ? new Date(Number(a.checkedAt)).getTime() : 0;
        const dateB = b.checkedAt ? new Date(Number(b.checkedAt)).getTime() : 0;
        return dateA - dateB;
      },
      render: (date: string) =>
        date ? new Date(Number(date)).toLocaleString() : '-',
    },
  ];

  const preMemberOonColumns = [
    ...(isAdmin
      ? [
          {
            title: 'Action',
            key: 'action',
            width: 200,
            render: (record: LeadEligibilityResponse) =>
              record.planNames ? (
                <Button
                  onClick={() => handleAddToInnList(record)}
                  type="primary"
                >
                  Add Plan Name
                </Button>
              ) : null,
          },
        ]
      : []),
    ...commonColumns,
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      width: 100,
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      width: 120,
      render: (date: string) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 200,
    },
  ];

  const preMemberInnColumns = [...commonColumns];

  const memberOonColumns = [
    ...(isAdmin
      ? [
          {
            title: 'Action',
            key: 'action',
            width: 200,
            render: (record: LeadEligibilityResponse) =>
              record.planNames ? (
                <Button
                  onClick={() => handleAddToInnList(record)}
                  type="primary"
                >
                  Add Plan Name
                </Button>
              ) : null,
          },
        ]
      : []),
    ...commonColumns,
    {
      title: 'DrP Link',
      key: 'drpLink',
      width: 150,
      render: (record: LeadEligibilityResponse) =>
        record.personId ? (
          <a href={`/members/${record.personId}`}>View Profile</a>
        ) : (
          '-'
        ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 200,
    },
  ];

  const memberInnColumns = [
    ...commonColumns,
    {
      title: 'DrP Link',
      key: 'drpLink',
      width: 150,
      render: (record: LeadEligibilityResponse) =>
        record.personId ? (
          <a href={`/members/${record.personId}`}>View Profile</a>
        ) : (
          '-'
        ),
    },
  ];

  const filteredData = useMemo(() => {
    const isMemberTab = activeTab.startsWith('member-');
    const data = isMemberTab
      ? realtimeData?.listRealtimeEligibilityChecks
      : leadData?.listLeadEligibilityCheckResponse;
    if (!data) return [];

    const eligiblePlanNames =
      eligiblePlanData?.listEligiblePayerPlanNames || [];

    let filtered = data.filter((record: LeadEligibilityResponse) => {
      const isInn =
        record.planNames && eligiblePlanNames.includes(record.planNames);

      switch (activeTab) {
        case 'pre-member-oon':
          return !isInn;
        case 'pre-member-inn':
          return isInn;
        case 'member-oon':
          return !isInn;
        case 'member-inn':
          return isInn;
        default:
          return false;
      }
    });

    return filtered;
  }, [leadData, realtimeData, activeTab, eligiblePlanData]);

  return (
    <div>
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Pre-Member OON" key="pre-member-oon">
          <StyledTable
            columns={preMemberOonColumns}
            dataSource={filteredData}
            loading={leadLoading}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: filteredData.length,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
            }}
            onChange={handleTableChange}
            scroll={{ x: true }}
          />
        </TabPane>
        <TabPane tab="Pre-Member INN" key="pre-member-inn">
          <StyledTable
            columns={preMemberInnColumns}
            dataSource={filteredData}
            loading={leadLoading}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: filteredData.length,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
            }}
            onChange={handleTableChange}
            scroll={{ x: true }}
          />
        </TabPane>
        <TabPane tab="Member OON" key="member-oon">
          <StyledTable
            columns={memberOonColumns}
            dataSource={filteredData}
            loading={realtimeLoading}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: filteredData.length,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
            }}
            onChange={handleTableChange}
            scroll={{ x: true }}
          />
        </TabPane>
        <TabPane tab="Member INN" key="member-inn">
          <StyledTable
            columns={memberInnColumns}
            dataSource={filteredData}
            loading={realtimeLoading}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: filteredData.length,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
            }}
            onChange={handleTableChange}
            scroll={{ x: true }}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export { LeadEligibilityPage };
